.services, .positions {
  position: absolute;
  width: 85%;
  background: $white;
  z-index: 20;
  height: 100%;

  &__container {
    overflow-y: scroll;
    height: 100%;
    padding: 70px 0;
  }

  .services__container {

    @include screen(650) {
      padding-bottom: 0;
    }
  }

  &__wrapper {
    flex-wrap: wrap;
    justify-content: space-between;

    .services__item {
      width: calc(50% - 120px);
      margin-bottom: 60px;

      @include screen(1450) {
        width: calc(50% - 60px);
        margin-bottom: 50px;
      }

      @include screen(1240) {
        width: calc(50% - 30px);
      }

      @include screen(650) {
        width: 100%;
      }

      .info-btn {
        font-weight: 600;
        margin-bottom: 10px;
      }

      h5 {
        margin: 25px auto 15px;
        font-size: 21px;
        line-height: 29px;
        font-weight: 600;
      }
    }
  }

  .btn {
    max-width: 200px;

    @include screen(500) {
      max-width: 130px;
    }
  }
}
