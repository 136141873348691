.career-page {

  .btn {
    max-width: 200px;

    @include screen(1024) {
      max-width: 350px;
    }

    @include screen(500) {
      max-width: 250px;
    }
  }

  .page_description {
    text-align: justify;
  }
}

.side_image-6 {
  background-image: url("../images/left_pic_6.jpg");

  &:after {
    border-bottom-color: $blue;
  }
}
