.modal {
  z-index: 100;

  &-backdrop {
    background-color: $white;

    &.show {
      opacity: 1;
      z-index: 99;
    }
  }

  &-fullscreen {

    .modal-content {
      background: none;
    }

    .modal-header {
      padding: 48px 75px 2px;
      height: 100px;
      z-index: 10;
      border: none;
      margin-bottom: 50px;

      @include screen(1199) {
        padding: 48px 15px 2px;
        margin-bottom: 30px;
      }

      @include screen(767) {
        padding: 16px 12px 2px;
        height: 50px;
        margin-bottom: 0;
      }

      img {
        padding: 0;
        z-index: 21;
        filter: invert(1);

        @include screen(1024) {
          filter: none;
        }

        @include screen(767) {
          width: 32px;
        }
      }

      .button-close {
        border: none;
      }
    }

    .modal-body {
      padding: 0 75px;

      @include screen(1240) {
        padding: 0 20px;
      }

      @include screen(1024) {
        padding: 90px 60px 0;
      }

      @include screen(650) {
        padding: 90px 30px 0;
      }

      @include screen(450) {
        padding: 90px 15px 0;
      }

      p {
        font-weight: 300;
        font-size: 24px;
        line-height: 33px;
        text-align: justify;
        color: $black;
        margin-bottom: 1.5rem;

        @include screen(1199) {
          font-size: 20px;
          line-height: 34px;
        }

        @include screen(767) {
          font-size: 14px;
          line-height: 21px;
        }
      }

      h2 {
        margin: 0 0 64px;
        color: $black;

        @include screen(767) {
          font-size: 42px;
          line-height: 51px;
          margin-bottom: 24px;
        }
      }

      span {
        font-weight: 700;
      }

      a {
        font-size: inherit;
      }
    }
  }

  &-desktop {
    @include screen(1024) {
      display: none;
    }
  }

  &-mobile {

    .modal__header {
      backdrop-filter: none;
    }

    @include screen-min(1025) {
      display: none;
    }
  }
}

.modal__header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 60px;
  min-height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  backdrop-filter: blur(8px);

  @include screen(650) {
    padding: 0 30px;
  }

  @include screen(450) {
    padding: 0 15px;
  }

  .back-btn {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }
}

.fade {
  transform: translateY(0);
}
