.footer {
  background: transparent;
  color: $white;
  font-weight: 600;
  transition: $transition;
  position: fixed;
  width: 100%;
  left: 0;
  top: 100%;
  height: 88px;
  z-index: 5;

  span {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
  }

  @include screen(1024) {
    height: 50px;
    display: flex;
    position: relative;
    background: $total_black;

    span {
      font-weight: 300;
      font-size: 12px;
      line-height: 17px;
    }
  }

  &.showing {
    top: calc(100% - 88px);

    @include screen(1024) {
      top: calc(100% - 50px);
    }
  }

  &.static {
    position: relative;
    z-index: 2;
  }

  .modalButton {
    cursor: pointer;
    border-bottom: 1px solid transparent;
    transition: $transition;

    &:hover {
      font-weight: 500;
      border-bottom: 1px solid $white;
    }
  }

  .container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 32px;

    @include screen(1024) {
      padding-top: 13px;
      padding-bottom: 13px;
      height: 100%;
    }
  }

  .legal-text {
    color: #b3b3b3;
    display: none;

    @include screen(1024) {
      display: block;
    }
  }
}
