.offering-page {

  .btn {
    max-width: 250px;

    @include screen(500) {
      max-width: 130px;
    }
  }
}

.side_image-3 {
  background-image: url("../images/left_pic_3.jpg");

  &:after {
    border-bottom-color: $salad;
  }
}
