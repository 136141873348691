@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  font-size: 16px;
  text-decoration: none;
  transition: .3s;
}

img {
  max-width: 100%;
  height: auto;
}

::-webkit-input-placeholder {
  font-style: italic;
  color: $gray;
}

::-moz-placeholder {
  font-style: italic;
  color: $gray;
}

:-ms-input-placeholder {
  font-style: italic;
  color: $gray;
}

:-moz-placeholder {
  font-style: italic;
  color: $gray;
}

::placeholder {
  font-style: italic;
  color: $gray;
}

html {
  height: -webkit-fill-available;
}

::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: 'Noto Sans', Arial, Tahoma, sans-serif;
  min-height: -webkit-fill-available;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

ul {
  list-style: none;
  padding: 0;
}

.swiper-slide {
  display: flex;
  position: relative;

  @include screen(1024) {
    flex-direction: column;
  }
}

.stepper {
  position: absolute;
  left: calc(50% - 10px);
  top: calc(50% - 152px);
  width: 22px;
  height: 304px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 9;
  transition: all ease-in-out .3s;

  &.show {
    display: flex;
    opacity: 1;

    @include screen(1024) {
      display: none;
    }
  }

  &.hide {
    opacity: 0;
    left: calc(25% - 10px);
    z-index: 2;
    pointer-events: none;
  }

  a {
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    border: 3px solid $white;
    background-color: $bullet_bg;

    &:nth-of-type(1).active, &:nth-of-type(1):hover {
      background-color: $light-blue;
    }

    &:nth-of-type(2).active, &:nth-of-type(2):hover {
      background-color: $orange;
    }

    &:nth-of-type(3).active, &:nth-of-type(3):hover {
      background-color: $salad;
    }

    &:nth-of-type(4).active, &:nth-of-type(4):hover {
      background-color: $green;
    }

    &:nth-of-type(5).active, &:nth-of-type(5):hover {
      background-color: $light-orange;
    }

    &:nth-of-type(6).active, &:nth-of-type(6):hover {
      background-color: $blue;
    }

    &:nth-of-type(7).active, &:nth-of-type(7):hover {
      background-color: $yellow;
    }
  }
}

@-webkit-keyframes appear {
  0% {
    transform: translate(30%, 0);
    opacity: 0;
  }
  50% {
    transform: translate(10%, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes desappear {
  0% {
    transform: translate(30%, 0);
    opacity: 0;
  }
  50% {
    transform: translate(10%, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.page {
  overflow: hidden;
  animation: ease-in-out;
  animation-name: appear;
  animation-duration: .8s;

  @include screen(1024) {
    animation: none;
  }

  &__starter {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &-block {
      width: 470px;
      height: 100%;
      border-radius: 0 0 20px 20px;
      transition: $transition;

      @include screen(1199) {
        width: 380px;
      }

      @include screen(575) {
        width: 280px;
      }

      @include screen(400) {
        width: 170px;
      }
    }
  }
}


@keyframes appear {
  0% {
    opacity: 0;
    transform: translate(30%, 0);
  }
  30% {
    opacity: 0;
    transform: translate(30%, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
.add-index {
  z-index: 10;
}

.visible-content {
  transition: .7s;

  @include screen(1024) {
    transition: none;
  }
}

.container-fluid {
  padding: 0 75px;

  @include screen(1589) {
    padding: 0 15px;
  }

  @include screen(991) {
    padding: 0 12px;
  }
}

#navigaton {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  padding: 48px 0 2px;
  height: 100px;
  z-index: 10;

  @include screen(1589) {
    padding-top: 16px;
  }

  @include screen(1024) {
    height: 50px;
    padding: 0;
    backdrop-filter: blur(8px);
  }

  .empty {
    width: 42px;

    @include screen-min(1025) {
      display: none;
    }
  }

  .navbar-toggler {
    height: 72px;
    width: 72px;
    border: none;
    transition: $transition;
    z-index: 21;

    @include screen(1024) {
      width: 42px;
      height: 42px;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.collapsed {

      .navbar-toggler-icon {
        position: relative;
        background-image: url("../images/menu.svg");
        background-color: transparent;
        width: 48px;
        height: 20px;

        @include screen(1024) {
          background-image: none;
          width: 24px;
          height: 2px;
          transition: background 0s 0.3s;

          &:before,
          &:after {
            content: '';
            opacity: 1;
            position: absolute;
            right: 0;
            background-color: $black;
            background-image: none;
            transition-duration: 0.3s, 0.3s;
            transition-delay: 0s, 0.3s;
          }

          &:before {
            top: -3px;
            width: 20px;
            height: 2px;
            transition-property: top, transform;
          }

          &:after {
            bottom: -3px;
            width: 16px;
            height: 2px;
            transition-property: bottom, transform;
          }
        }

        @include screen-min(1025) {

          &:after {
            content: '';
            opacity: 1;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url("../images/menu.svg");
            background-color: transparent;
            transition: $transition;
            transform: none;
          }

          &:before {
            display: none;
          }
        }
      }

      &:hover {

        @include screen-min(1025) {
          .navbar-toggler-icon {

            &:after {
              left: -12px;
            }
          }
        }
      }
    }

    &:not(.collapsed) {

      .navbar-toggler-icon {
        position: relative;
        background-image: url("../images/cross.svg");
        width: 36px;
        height: 36px;
        transition: background 0s 0.3s;

        @include screen(1024) {
          background-image: none;
          width: 24px;
          height: 2px;

          &:before,
          &:after {
            content: '';
            opacity: 1;
            position: absolute;
            right: 0;
            width: 100%;
            height: 2px;
            background-color: $black;
            transition-duration: 0.3s, 0.3s;
            transition-delay: 0.3s, 0s;
          }

          &:before {
            top: 0;
            transform: rotate(45deg);
            transition-property: top, transform;
          }

          &:after {
            bottom: 0;
            transform: rotate(-45deg);
            transition-property: bottom, transform;
          }
        }
      }

      @include screen-min(1025) {

        &:hover {
          background-color: transparent;
          transform: rotate(-360deg);
        }
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  .navbar-collapse {
    background: $white;
    height: 100vh;
    overflow: hidden;
    display: block;
    position: absolute;
    width: 100%;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
    transform: translateY(-100%);

    top: 0;
    z-index: -1;

    &.show {
      visibility: visible;
      opacity: 1;
      z-index: 20;
      transform: translateY(0);
    }

    .header-footer {
      font-family: "Nunito Sans", sans-serif;
      font-size: 16px;
      color: $black;
      position: fixed;
      bottom: 32px;
      left: 75px;
      display: flex;
      align-items: center;
      width: 100%;

      @include screen(1589) {
        left: 0.75rem;
      }

      @include screen(1024){
        bottom: 13px;
        justify-content: center;
        left: 0;
      }

      @include screen(440) {
        font-size: 12px;
      }

      .modalButton {
        cursor: pointer;
        border-bottom: 1px solid transparent;
        transition: $transition;

        &:hover {
          border-bottom: 1px solid $black;
        }
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 100%;
      max-height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 250px;
      padding-bottom: 10px;
      padding-left: 75px;
      max-width: 900px;
      position: relative;
      z-index: 2;

      @include screen(1589) {
        padding-left: 0.75rem;
      }

      @include screen(1024) {
        justify-content: flex-start;
        padding-top: 76px;
        min-height: 90%;
        height: 90%;
        padding-bottom: 0;
        overflow: visible;

        .nav-link {
          height: 100%;
        }
      }

      .nav-link {
        font-family: Montserrat, sans-serif;
        color: $black;
        text-transform: uppercase;
        font-size: calc(7.3vh - 20px);
        line-height: 1.217;
        font-weight: 700;
        display: flex;
        width: 100%;
        position: relative;
        left: 0;
        transition: $transition;
        margin-bottom: 1em;

        @include screen(1450) {
          font-size: calc(6.3vh - 20px);
        }

        @include screen(1140) {
          font-size: calc(5.3vh - 20px);
        }

        @include screen(1024) {
          font-size: calc(7.3vh - 20px);;
          display: flex;
          align-items: center;
          padding: 10px;
          margin-bottom: 0;
        }

        @include screen(440) {
          font-size: 24px;
        }

        span {
          color: $gray;
          font-weight: 200;
          display: inline-block;
          text-align: left;
          margin-right: 25px;

          @include screen(1199) {
            max-width: 140px;
            margin-right: 25px;
          }
        }

        &:hover {
          @include screen-min(1025) {

            left: 30px;

            &:after {
              content: '';
              width: 250px;
              position: absolute;
              top: 100%;
              left: -15px;
            }
          }
        }

        &.home:hover {

          span {
            color: $light-blue;
          }

          &:after {
            content: '';
            border-bottom: 4px solid $light-blue;
          }
        }

        &.about:hover {

          span {
            color: $orange;
          }

          &:after {
            content: '';
            border-bottom: 4px solid $orange;
          }
        }

        &.offering:hover {

          span {
            color: $salad;
          }

          &:after {
            content: '';
            border-bottom: 4px solid $salad;
          }
        }

        &.cyprus:hover {

          span {
            color: $green;
          }

          &:after {
            content: '';
            border-bottom: 4px solid $green;
          }
        }

        &.portugal:hover {

          span {
            color: $light-orange;
          }

          &:after {
            content: '';
            border-bottom: 4px solid $light-orange;
          }
        }

        &.career:hover {

          span {
            color: $blue;
          }

          &:after {
            content: '';
            border-bottom: 4px solid $blue;
          }
        }

        &.contact:hover {

          span {
            color: $yellow;
          }

          &:after {
            content: '';
            border-bottom: 4px solid $yellow;
          }
        }
      }
    }
  }

  .logo {
    padding: 0;
    z-index: 21;

    img {
      filter: invert(1);

      @include screen(1024) {
        width: 36px;
        height: 36px;
      }
    }
  }

  .nav-logo {
    img {
      filter: invert(0);

      @include screen(1024) {
        filter: invert(1);
      }
    }
  }
}

.btn {
  color: $total_black;
  max-width: 268px;
  width: 100%;
  max-height: 73px;
  padding: 0 0 20px;
  text-align: center;
  display: inline-block;
  border: none;
  font-size: 24px;
  line-height: 33px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  border-bottom: 4px solid $light-blue;
  overflow: hidden;

  @include screen(1024) {
    font-size: 21px;
    line-height: 28px;
    padding: 12px 23px;
    max-width: 170px;
  }

  @include screen(500) {
    padding: 12px;
    font-size: 18px;
    line-height: 20px;
  }

  &:disabled {
    cursor: not-allowed;
  }

  @include screen-min(1024) {

    &:not(:disabled):hover {
      .front {
        top: -50px;
      }

      .back {
        top: -73px;
      }
    }
  }

  .front, .back {
    padding: 20px 0;
    position: relative;
    top: 0;
    transition: $transition;
  }

  .back {
    background: $light-blue;
  }

  span {
    position: relative;
    z-index: 3;
  }
}

.button-close {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid $light;
  background: none;
  transition: $transition;
  z-index: 21;

  @include screen(600) {
    width: 32px;
    height: 32px;
  }

  &-icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 36px;
    height: 36px;
    overflow: hidden;
    transition: $transition;
    background-size: 100%;
    background-image: url("../images/cross.svg");

    @include screen(991) {
      width: 12px;
      height: 12px;
    }
  }

  &:hover {
    background: transparent;
    transform: rotate(-90deg);

    .button-close-icon {
      background-image: url("../images/cross.svg");
    }
  }
}

.page {
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;

  @include screen(1024) {
    min-height: auto;
  }

  .page__content {
    padding: 70px;
    width: 50vw;
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: all ease-in-out .3s;

    @include screen(1024) {
      width: 100%;
      padding: 10% 3% 15%;
      transition: none;
    }

    .page__content_container {
      transition: all ease-in-out .3s;
      z-index: 4;

      @include screen(1024) {
        transition: none;
      }
    }

    &.hide .page__content_container {
      opacity: 0;
      z-index: 2;
      pointer-events: none;
    }

    .read-more {
      position: fixed;
      top: 0;
      right: -25vw;
      height: 100%;
      width: 75vw;
      opacity: 0;
      transition: all ease-in-out .4s;
      padding: 0 60px;
      z-index: 2;
      pointer-events: none;

      @include screen(1024) {
        width: 100vw;
        left: 0;
        right: auto;
        transition: $transition;
        transform: translateX(100%);

        &.show {
          transform: translateX(0);
        }
      }

      @include screen(650) {
        padding: 0 30px;
      }

      @include screen(450) {
        padding: 0 15px;
      }

      &.show {
        opacity: 1;
        right: 0;
        z-index: 15;
        pointer-events: auto;
      }
    }

    .home__nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      left: 50%;
      top: calc(50% + 10vw);
      transform: translate(-50%, -50%);
      z-index: 6;

      a {
        text-decoration: none;
        margin: 0 35px;
        font-size: 28px;
        line-height: 38px;
        color: $light-gray;
        font-weight: bold;
        padding-bottom: 10px;
        background-position: 0 100%;
        background-repeat: no-repeat;
        background-size: 0 4px;
        transition: background-size $transition;
        border-radius: 2px;

        @include screen(1199) {
          font-size: 24px;
          line-height: 34px;
        }
      }

      a:hover {
        background-size: 100% 4px;
      }
    }
  }
}

.side_image {
  position: relative;
  min-height: 100vh;
  min-width: 50vw;
  transition: min-width ease-in-out .3s;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @include screen(1024) {
    min-height: 50vh;

    &:after {
      content: "";
      width: 100%;
      border-bottom: $light-blue solid 4px;
      position: absolute;
      bottom: 0;
    }
  }
}

.side_image-opened-page {
  min-width: 25vw;
}

.close_button {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 3px solid $white;
  background-color: $bullet_bg;
  position: absolute;
  left: -15px;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 14px;
    height: 14px;
    filter: invert(1);
  }

  &:hover {
    background-color: $black;
  }
}

.swiper-container {
  height: 100vh;
  width: 110%;

  .swiper-pagination {
    position: fixed;
    left: 50%;
  }
}

.page_number {
  font-family: Montserrat, sans-serif;
  color: $gray;
  font-size: 48px;
  line-height: 59px;
  font-weight: 300;

  @include screen(1024) {
    color: $white;
    position: absolute;
    bottom: 20px;
    left: 15px;
  }

  @include screen(500) {
    bottom: 10px;
    left: 10px;
    font-size: 32px;
    line-height: 39px;
  }
}

h2 {
  font-family: Montserrat, sans-serif;
  font-size: 72px;
  line-height: 88px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 60px;

  @include screen(1510) {
    font-size: 64px;
    line-height: 74px;
  }

  @include screen(1450) {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 30px;
  }

  @include screen(1240) {
    font-size: 44px;
    line-height: 54px;
  }

  @include screen(500) {
    font-size: 32px;
    line-height: 42px;
  }
}

.page_description {
  font-size: 18px;
  line-height: 25px;
  font-weight: 300;
  padding-right: 100px;
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;

  @include screen(1450) {
    margin-bottom: 30px;
    padding-right: 20px;
  }

  @include screen(1240) {
    margin-bottom: 30px;
    padding-right: 0;
  }

  @include screen(500) {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 2rem;

    @include screen(1024) {
      margin-bottom: 1rem;
    }
  }
}

.info-btn {
  display: inline-block;
  cursor: pointer;
  align-self: end;
  font-weight: 700;
  transition: $transition;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid $black;
    position: relative;
  }
}
