.cyprus_office-page {

  .btn {
    max-width: 300px;
  }

  .page_description {
    text-align: justify;
  }
}

.side_image-4 {
  background-image: url("../images/left_pic_4.jpg");

  &:after {
    border-bottom-color: $green;
  }
}
