.contact-page {

    .btn {
        max-width: 240px;

        @include screen(500) {
            max-width: 170px;
        }
    }

    .page_description {
        text-align: justify;

        a {
            display: inline-block;
            font-size: 18px;
            font-weight: 500;
            color: $mail-color;
            border-bottom: 1px solid transparent;
            max-width: fit-content;

            &:hover {
                color: $mail-color;
                border-bottom: 1px solid $mail-color;
                position: relative;
                left: 1px;

                @include screen(1024) {
                    border-bottom: 1px solid transparent;
                    left: 0;
                }
            }
        }

        @include screen(1024) {
            padding-bottom: 1em;
        }
    }
}

.side_image-7 {
    background-image: url("../images/left_pic_7.jpg");

    &:after {
        border-bottom-color: $yellow;
    }
}
