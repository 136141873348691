.portugal_office-page {

  .btn {
    max-width: 210px;
  }

  .page_description {
    text-align: justify;
  }
}

.side_image-5 {
  background-image: url("../images/left_pic_5.jpg");

  &:after {
    border-bottom-color: $light-orange;
  }
}
