.whoweare-page {

  p {
    text-align: justify;
  }

  .btn {
    max-width: 300px;
  }
}

.side_image-2 {
  background-image: url("../images/left_pic_2.jpg");

  &:after {
    border-bottom-color: $orange;
  }
}
