.positions__container {
  #BambooHR-ATS {
    padding-right: 80px;

    @include screen(1589) {
      padding-right: 25px;
    }

    .BambooHR-ATS-board {
      font-family: inherit;

      h2 {
        font-size: 72px;
        color: inherit;

        @include screen(1450) {
          font-size: 48px;
          line-height: 58px;
          margin-bottom: 30px;
        }

        @include screen(1240) {
          font-size: 44px;
          line-height: 54px;
        }

        @include screen(500) {
          font-size: 32px;
          line-height: 42px;
        }
      }

      .BambooHR-ATS-Jobs-List {

        a {
          font-weight: 600;
        }

        .BambooHR-ATS-Location {
          font-size: 16px;
          font-weight: 300;
        }

        @include screen(900) {
          padding-left: 0 !important;

          .BambooHR-ATS-Jobs-Item {
            display: flex;
            flex-direction: column;
            padding-bottom: 40px;

            a {
              font-size: 18px;
              line-height: 25px;
              font-weight: 600;
              align-self: start;
            }

            .BambooHR-ATS-Location {
              font-size: 16px;
              line-height: 22px;
              font-weight: 300;
              padding-top: 3px !important;
            }
          }
        }
      }
    }

    .BambooHR-ATS-Jobs-Item {

      a {
        color: $black;
        display: inline-block;
        cursor: pointer;
        align-self: end;
        font-weight: 500;
        transition: $transition;
        border-bottom: 1px solid transparent;

        &:before {
          content: "[ ";
        }

        &:after {
          content: " ]";
        }

        &:hover {
          border-bottom: 1px solid $black;
          position: relative;
          top: -1px;
        }
      }
    }

    .BambooHR-ATS-Location {
      color: $black;
      font-size: 18px;
      line-height: 25px;
      font-weight: 300;
    }
  }
}
