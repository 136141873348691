$white: #fff;
$light-gray: rgba(255, 255, 255, 0.7);
$light: #eef0fc;
$gray: #1f0b0033;
$black: #1f0b00;
$total_black: #000;
$pages-bg: #f8feff;
$footer: #1a1b21;
$dark: #624200;
$btn-orange: #ffb822;
$yellow: #f5f953;
$light-orange: #fec920;
$orange: #ff9a23;
$pink: #f4516c;
$pink_bg: #ff738a;
$light-blue: #0be2ff;
$blue: #2999ff;
$mail-color: #29bfff;
$salad: #3de9ca;
$green: #66cc15;
$menu-button: #eef0fc;
$bullet_bg: #cacaca;
$transition: .5s cubic-bezier(1, -0.01, 0, 1);
