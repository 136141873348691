@mixin screen($width) {
  @media(max-width: $width + px) {
    @content
  }
}

@mixin screen-min($width) {
  @media(min-width: $width + px) {
    @content
  }
}
