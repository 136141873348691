.home-page {

  .btn {
    max-width: 210px;
  }
}

.side_image-1 {
  background-image: url("../images/left_pic_1.jpg");
}
